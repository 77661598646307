import React from "react"
import LayoutCourse from "../../../../../layouts/course"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"
import CourseFooterNext from "../../../../../components/course-footer-next"
import {
  getCourseNavigation,
  useCourseStore,
} from "../../../../../store/courses"
import TaskSidebar from "../../../../../components/task-sidebar"
import NotationGroup from "../../../../../components/notation-group"
import NotationHidden from "../../../../../components/notation-hidden"
import SubstantiveAdjektiveNotation from "../../../../../content/kurse/haelfte-des-lebens/01-zwei-haelften/substantive-adjektive-notation"
import Heading from "../../../../../components/heading"
import Paragraph from "../../../../../components/paragraph"

const Page = () => {
  const { getAnswer } = useCourseStore()
  const navigation = getCourseNavigation({ courseId: "haelfte-des-lebens" })

  const task = {
    courseId: "haelfte-des-lebens",
    chapterId: "01-zwei-haelften",
    taskId: "saetze-ausmisten",
  }

  const answers = getAnswer({
    ...task,
  })

  return (
    <LayoutCourse
      navigation={navigation}
      footer={
        <CourseFooterNext
          title="Alles klar soweit?"
          label="Weiter"
          to="/kurse/haelfte-des-lebens/01-zwei-haelften/substantive-und-adjektive"
        />
      }
    >
      <Seo title="Sätze ausmisten" />
      <Stack>
        <Heading as="h2" level={2}>
          Sätze ausmisten
        </Heading>
        <TaskSidebar
          main={
            <NotationGroup initialState={answers}>
              <SubstantiveAdjektiveNotation notationType={NotationHidden} />
            </NotationGroup>
          }
          sidebar={
            <Paragraph>
              Vor allem in der 1. Strophe finden sich einige Ausschmückungen und
              Anreicherungen, die streng genommen herausgekürzt werden könnten.
              Doch ginge dabei auch etwas verloren. Die aufgerufenen Bilder
              werden schwächer. Die Stimmung verändert sich. Sehen wir uns die
              verwendeten Wörter und Wortarten einmal genauer an...
            </Paragraph>
          }
        />
      </Stack>
    </LayoutCourse>
  )
}

export default Page
